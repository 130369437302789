<template>
  <span>
    {{dots}}
  </span>
</template>

<script>
export default {
  props: {
    speedInMs: Number
  },
  data () {
    return {
      dots: ''
    }
  },
  created () {
    this.interval = setInterval(() => {
      this.tick()
    }, this.speedInMs || 300)
  },
  methods: {
    tick () {
      if (this.dots === '...') {
        this.dots = '.'
      } else {
        this.dots = this.dots + '.'
      }
    }
  }
}
</script>
